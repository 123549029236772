import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import {
  Image,
  Delete,
  InsertDriveFile,
  KeyboardBackspace,
} from "@mui/icons-material";
import { useUser } from "@descope/react-sdk";
import { useTranslation } from "react-i18next";

import MessageFormatter from "./MessageFormatter";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getChildList } from "../../../redux/features/client-main/client-list-slice";
import {
  PostComments,
  setTranslation,
  openMessage,
  getTranslationMessageId,
} from "../../../redux/features/messages/message-slice";

import { dateToFromNowDaily } from "../../../helper/reverselist";

import { IComments } from "../../../type/message";

import ClientImg from "../../../assets/images/Image-svg/client-large.svg";
import ProviderImg from "../../../assets/images/Image-svg/provider-large-vertical.svg";
import { ReactComponent as Download } from "../../../assets/images/Image-svg/Download.svg";

import "../message-details/message-details.scss";

const InternalMessages = () => {
  //#region  variable region

  //dispatch
  const dispatch = useAppDispatch();

  // get user data and auth
  const { user } = useUser();

  //translation
  const { t } = useTranslation();

  //handles selected language
  const [selectedLang, setSelectedLang] = useState<string>("English");

  //used to handle Internal comments
  const [internalComment, setInternalComment] = useState<string>("");

  //handle error
  const [hasError, SetHasError] = useState<boolean>(false);

  //handle sub comment attachments
  const [subCommentFiles, setSubCommentFiles] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  //fetch all messages
  const { TranslatedMessages, openedMessage } = useAppSelector(
    (state) => state.messagesSlice
  );

  const currentMessage = TranslatedMessages
    ? TranslatedMessages
    : openedMessage;

  const languageMap: Record<"en" | "es" | "zh-cn", string> = {
    en: "English",
    es: "Spanish",
    "zh-cn": "Chinese",
  };
  
  //#endregion

  //#region methods region

  //handle leave comments
  const handleLeave = () => {
    if (internalComment !== "") {
      getAllMessage(internalComment);
      setInternalComment("");
      SetHasError(false);
    } else {
      SetHasError(true);
    }
  };

  //handles drop files
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setSubCommentFiles([...subCommentFiles, ...files]);
  };

  //handles dragover files
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // handle update and fetch all template
  const getAllMessage = async (internalComment: string) => {
    if (currentMessage !== null)
      try {
        // eslint-disable-next-line
        await dispatch(
          PostComments({
            messageId: currentMessage.id,
            comment: internalComment,
            entityId: currentMessage.entityId,
            entityType: "Client",
            files: subCommentFiles,
          })
        );
      } catch (error: any) {
        console.log(error);
      } finally {
        setSubCommentFiles([]);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //handle message translation
  const translateText = async (lang: string) => {
    try {
      setLoading(true);
      if (!lang) {
        setSelectedLang("");
        return dispatch(setTranslation(null));
      }
      if (currentMessage) {
        await dispatch(
          getTranslationMessageId({
            messageId: currentMessage.id,
            language: lang,
          })
        );
      } else {
        dispatch(setTranslation(null));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeLanguage = (lang: string) => {
    // if (lang === "es") {
      setSelectedLang(lang);
      translateText(lang);
    // } else {
      // setSelectedLang("English");
      // translateText("English");
    // }
  };
  // console.log(selectedLang, "selected");

  useEffect(() => {
    if (currentMessage)
      if (currentMessage.seenUserId.includes(user?.userId)) {
        dispatch(getChildList({ email: user?.email! }));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessage]);

  if (!currentMessage) {
    return null;
  }
  //#endregion

  return (
    <Grid item xs={16} md={12}>
      <Grid sx={{ display: "flex", gap: "15px", marginBottom: "10px" }}>
        <Button
          onClick={() => {
            dispatch(setTranslation(null));
            dispatch(openMessage(null));
          }}
          className="backBtn"
          startIcon={<KeyboardBackspace />}
          style={{ marginBottom: 0 }}
        >
          {t("main.message.back")}
        </Button>
        <div
          style={{
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
            height: "40px",
            alignItems: "center",
            display: "flex",
            // width: "150px",
          }}
        >
          <FormControl sx={{ width: "130px" }}>
            <Select
              className="demo-simple-select"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedLang}
              displayEmpty={true}
              renderValue={(value) => {
                if (loading) {
                  return <CircularProgress size={20} />;
                } else{
                  return (
                    languageMap[value as "en" | "es" | "zh-cn"] || "Translate"
                  );
                }
                  
              }}
              onChange={(e: any) => {
                handleChangeLanguage(e.target.value);
              }}
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
            >
              <MenuItem
                value={"en"}
                key={"en"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                English
              </MenuItem>
              <MenuItem
                value={"es"}
                key={"es"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Spanish
              </MenuItem>
              <MenuItem
                value={"zh-cn"}
                key={"zh-cn"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Chinese
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        {TranslatedMessages && (
          <div
            style={{
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
              borderRadius: "4px",
              height: "30px",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              padding: "5px",
            }}
          >
            <Box onClick={() => translateText("")}>
              <strong>See original</strong>
            </Box>
          </div>
        )}
      </Grid>

      <Grid
        className="messageInternal-block"
        item
        xs={16}
        key={currentMessage.id}
      >
        <Box className="compose-block">
          <span className="commentListItem__user-img">
            <img
              src={
                currentMessage?.user?.profile.url ||
                currentMessage?.user?.userType === "Client"
                  ? ClientImg
                  : ProviderImg
              }
              className="userSmallCircle"
              alt=""
            />
          </span>
          <div className="commentListItem__user-info">
            <div className="compose-user-heading">
              <h5 className="compose-user-name">
                {currentMessage?.user?.name}
              </h5>
              <h6 className="compose-user-time">
                <span>{dateToFromNowDaily(currentMessage?.createdOn)}</span>
              </h6>
            </div>
          </div>
        </Box>
        <Box>
          <div className="messageTicket-number">
            <strong>Subject: {currentMessage?.subject}</strong>
          </div>
        </Box>
        <Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
          <MessageFormatter message={currentMessage?.message} />
        </Box>
        <div style={{ textDecoration: "none", display: "flex", gap: "10px" }}>
          {currentMessage?.files.map((file, index) => (
            <span key={index}>
              <a
                rel="noreferrer"
                target="_blank"
                href={`${file.url}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {["JPG", "JPEG", "PNG"].includes(
                  file?.url?.split(".").pop()?.toUpperCase() || ""
                ) ? (
                  <Image style={{ color: "#096dd9" }} />
                ) : (
                  <InsertDriveFile style={{ color: "#096dd9" }} />
                )}
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  }}
                  title={file.fileName}
                >
                  {file.fileName}
                </span>
                <IconButton style={{ background: "transparent" }}>
                  <Download />
                </IconButton>
              </a>
            </span>
          ))}
        </div>
      </Grid>

      <Grid item xs={16} md={16} className="messagecomment-block">
        <Grid className="commentForm-lavel" item xs={16} md={12}>
          {t("main.message.follow_ups")}
        </Grid>
        <Box className="commentForm">
          <div
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
          >
            <TextareaAutosize
              disabled={TranslatedMessages !== null}
              value={internalComment}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setInternalComment(e.target.value);
                if (e.target.value !== "") {
                  SetHasError(false);
                }
              }}
              placeholder={t("main.message.reply_here")}
              minRows={2}
            />
            <Button onClick={() => handleLeave()}>
              {t("main.message.add_follow_up")}
            </Button>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {subCommentFiles &&
              subCommentFiles.map((file, index) => {
                return (
                  <div className="selectedIn-file">
                    <h1>{file.name}</h1>
                    <span>
                      <Delete
                        onClick={() => {
                          setSubCommentFiles(
                            subCommentFiles.filter(
                              (_, fileIndex) => fileIndex !== index
                            )
                          );
                        }}
                      />
                    </span>
                  </div>
                );
              })}
          </div>
        </Box>
        {hasError && (
          <FormHelperText style={{ color: "#d32f2f" }}>
            {t("main.message.please_type_anything")}
          </FormHelperText>
        )}
        {currentMessage?.comments?.length > 0 &&
          currentMessage?.comments?.map((item: IComments, index: number) => {
            return (
              <Box className="commentListItem generated" key={index}>
                <Box className="compose-block">
                  <span className="commentListItem__user-img">
                    <img
                      src={item?.fromUser?.profilePictureUrl || ClientImg}
                      className="userSmallCircle"
                      alt=""
                    />
                  </span>
                  <div className="commentListItem__user-info">
                    <div className="compose-user-heading">
                      <h5 className="compose-user-name">
                        <strong>
                          {/* external in-mail, always use fromname" */}
                          {/* {item?.fromName} */}
                          {item?.fromUser?.firstName} {item?.fromUser?.lastName}
                        </strong>
                      </h5>
                      <h6 className="compose-user-time">
                        <span>{dateToFromNowDaily(item.createdAt)}</span>
                      </h6>
                    </div>

                    <div className="compose-user-comment">{item.comment}</div>
                    <div
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      {item?.files?.map((file) => {
                        return (
                          <span>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={`${file.url}`}
                              style={{
                                textDecoration: "none",
                                color: "black",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {["JPG", "JPEG", "PNG"].includes(
                                file?.url?.split(".").pop()?.toUpperCase() || ""
                              ) ? (
                                <Image style={{ color: "#096dd9" }} />
                              ) : (
                                <InsertDriveFile style={{ color: "#096dd9" }} />
                              )}
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                }}
                                title={file.fileName}
                              >
                                {file.fileName}
                              </span>
                              <IconButton style={{ background: "transparent" }}>
                                <Download />
                              </IconButton>
                            </a>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </Box>
              </Box>
            );
          })}
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default InternalMessages;
