import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "@descope/react-sdk";
import {
  InTakeStageProcess,
  OngoingProcess,
} from "../../constants/client-status";
import { IChildList, IFormData } from "../../type/child-info";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import {
  getChildDetails,
  getClientFilesById,
} from "../../redux/features/client-main/client-list-slice";
import { ReactComponent as ScheduleIcon } from "../../assets/icons/Schedule.svg";
import "./home.style.scss";
import FileUpload from "react-material-file-upload";
import { setResponseValue } from "../../redux/features/api-response/api-response";
import { GetAssessmentById, setLoadingStatus } from "../../redux/features/form/form-slice";
import { useSelector } from "react-redux";
import { OrgService } from "../../constants/organization";
import Loader from "../../layouts/loader/Loader";
import { useTranslation } from "react-i18next";
import { Board } from "../../type/client";
import { getAllClientIntakeStage } from "../../redux/features/client-intake-stage/client-intake-slice";
import ClientService from "../../service/client.service";

interface IClientHomeComponents {
  ChildData: IChildList;
}

const HomeSubPage: React.FC<IClientHomeComponents> = ({ ChildData }) => {
  //#region Variable region

  //variable to navigation
  const navigate = useNavigate();

  //dispatch
  const dispatch = useAppDispatch();

  // get user data and auth
  // const { user, isAuthenticated } = useAuth();

  const { user } = useUser();

  //translation
  const { t, i18n } = useTranslation();

  //handles the SavePopup
  const [saveClient, setSaveClient] = useState<boolean>(false);

  ///used to handle Dialog
  const [popup, setPopup] = useState<boolean>(false);

  // store Child Details
  const currentChild = useAppSelector(
    (state) => state.clientSlice.currentChild
  );

  const org = useAppSelector((state) => state.organizationSlice.orgData);

  //counter for assessment form selection
  const [count, setCount] = useState<number>(0);

  //used to handle Intakeurl
  const [intakeFormUrl, setIntakeFormUrl] = useState<string>("");

  // used to handle formurl
  const [formId, setFormId] = useState<string>("");

  // used to handle form request status
  const [requestStatus, setFormStatus] = useState<string>("");

  // used to handle form type
  const [, setFormType] = useState<string>("");

  //used to handle files
  const [files, setFiles] = useState<File[]>([]);

  //
  const [open, setIsOpen] = useState<boolean>(false);

  const [filterdIntakeStages, setFilteredIntakeStages] =
    useState(InTakeStageProcess);

  const intakeStages = useSelector<RootState, Board>(
    (state) => state.clientIntakeSlice
  );

  const isLoading = useSelector<RootState, boolean>(
    (state) => state.responseSlice.pending
  );
  //#endregion

  //#region method region

  //handle view messages
  // eslint-disable-next-line
  const handleViweMessage = () => {
    navigate("/center");
  };

  // file submit handler
  const uploadDocument = async (clientId: any, newFiles: any[]) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status } = await ClientService.postClientFiles(
        "Intake",
        "Client",
        "Client",
        clientId,
        newFiles
      );

      if (status) {
        setIsOpen(true);
        dispatch(getClientFilesById({ clientId }));
        dispatch(getChildDetails({ id: ChildData?.id }));

        // dispatch(setClientFilesDataChange({}));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  // eslint-disable-next-line
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleOpenForm = (item: IFormData, index: number) => {
    setPopup(true);
    setSaveClient(true);
    dispatch(GetAssessmentById({ id: item.id }));
    setFormId(item.id);
    setCount(index);
    setFormStatus(item.status);
    setFormType(item.form.formType);
    dispatch(setLoadingStatus("Pending"));
  };
  //#endregion

  //#region UseEffect region

  //re-fetch Childslists
  useEffect(() => {
    if (ChildData) {
      dispatch(getChildDetails({ id: ChildData?.id }));
      // dispatch(GetAssessmentList({ id: ChildData?.id }));
    }

    if (intakeFormUrl !== "") setIntakeFormUrl("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, ChildData?.id]);

  useEffect(() => {
    const permittedStages = intakeStages.stages.filter((stage) =>
      intakeStages.permission?.viewClient?.includes(stage.id)
    );

    const result = InTakeStageProcess.filter((item) =>
      permittedStages.some((stage) => stage.stageName === item.eng)
    );
    setFilteredIntakeStages(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intakeStages?.id]);

  useEffect(() => {
    dispatch(getAllClientIntakeStage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion
  return (
    <>
      {isLoading && <Loader isShow={isLoading} />}
      <Grid>
        <Box className="pageTemplate__head">
          <Typography className="pageTemplate__title">
            {t("main.home.welcome")},{" "}
            {ChildData
              ? ChildData?.childFirstName + " " + ChildData?.childLastName
              : user?.name
              ? user.name
              : user?.email?.split("@")[0]}
            !
          </Typography>
        </Box>
        {
          //if there is only 1 service MentalHealth, we do not show this.

          !(
            org?.orgService?.length === 1 &&
            org.orgService.includes(OrgService.MentalHealth)
          ) &&
            (ChildData?.intakeStage !== "Intake Complete" ? (
              <Box className="whiteCard">
                <Box className="whiteCard__header">
                  <span className="whiteCard__headingIcon">
                    <ScheduleIcon />
                  </span>
                  <Box className="whiteCard__heading">
                    <Typography className="whiteCard__title">
                      {t("main.home.intake_process")}
                    </Typography>
                    <Typography className="whiteCard__text">
                      {t("main.home.keep_track")}
                    </Typography>
                  </Box>
                </Box>

                <Box className="ui-breadcrumb">
                  <ul>
                    {filterdIntakeStages.map(
                      (
                        item: { eng: string; Spanish: string; Chinese: string },
                        index: number
                      ) => {
                        return (
                          <li
                            key={index}
                            className={
                              ChildData?.intakeStage === item.eng
                                ? "ui-breadcrumb__item activeTab"
                                : "ui-breadcrumb__item"
                            }
                          >
                            <div className="ui-breadcrumb__item-link">
                              <span>
                                {i18n.language === "es"
                                  ? item.Spanish
                                  : i18n.language === "zh-CN"
                                  ? item.Chinese
                                  : item.eng}
                              </span>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </Box>
              </Box>
            ) : (
              <Box className="whiteCard">
                <Box className="whiteCard__header">
                  <span className="whiteCard__headingIcon">
                    <ScheduleIcon />
                  </span>
                  <Box className="whiteCard__heading">
                    <Typography className="whiteCard__title">
                      {t("main.home.ongoing_process")}
                    </Typography>
                    <Typography className="whiteCard__text">
                      {t("main.home.keep_track")}{" "}
                      {t("main.home.ongoing_process")}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box className="ui-breadcrumb">
                  <ul>
                    {OngoingProcess.map(
                      (
                        item: { eng: string; Spanish: string; Chinese: string },
                        index: number
                      ) => {
                        return (
                          <li
                            key={index}
                            className={
                              //ChildData?.intakeStage === item
                              index === 0 //temp solution, changed by Hui
                                ? "ui-breadcrumb__item activeTab"
                                : "ui-breadcrumb__item"
                            }
                          >
                            <div className="ui-breadcrumb__item-link">
                              <span>
                                {" "}
                                {i18n.language === "es"
                                  ? item.Spanish
                                  : i18n.language === "zh-CN"
                                  ? item.Chinese
                                  : item.eng}
                              </span>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </Box>
              </Box>
            ))
        }

        <Grid container spacing={3} mt="20px">
          <Grid item lg={6} xs={12}>
            <Box mb={3} className="ActionStatus">
              {t("main.home.insurance-info-change")}
              {", "}
              {t("main.home.please_upload")}{" "}
              <strong> {t("main.home.your-newCard")}</strong>.
            </Box>
            <Box className="actionCard">
              <Box className="actionCard__body">
                {
                  // currentChild?.organizationOptions
                  //   .allowToUploadFrontInsuranceCard && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <Box
                      className={"actionCard__list"}
                      style={{
                        boxShadow: "2px 2px 7px #DBE2E9",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <Typography className="actionCard__list-title">
                        <span
                          style={{
                            marginLeft: "12px",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("main.home.please_upload")} {t("main.home.front")}
                        </span>
                      </Typography>
                      {/* <span className="actionCard__list-status">Upload</span> */}
                      <div
                        className="uploadCoustomBtn actionCard__list-status"
                        style={{ backgroundColor: "#096dd9", color: "white" }}
                      >
                        {t("main.upload")}
                        <FileUpload
                          multiple
                          value={files}
                          onChange={(e: File[]) => {
                            uploadDocument(ChildData?.id, [...files, ...e]);
                            setFiles([...files, ...e]);
                          }}
                        />
                      </div>
                    </Box>
                  </Box>
                  // )
                }
                {
                  // currentChild?.organizationOptions
                  //   .allowToUploadBackInsuranceCard && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <Box
                      className={"actionCard__list"}
                      style={{
                        boxShadow: "2px 2px 7px #DBE2E9",

                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <Typography className=" actionCard__list-title">
                        <span
                          style={{
                            marginLeft: "12px",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("main.home.please_upload")} {t("main.home.back")}
                        </span>
                      </Typography>

                      {/* <span className="actionCard__list-status">Upload</span> */}
                      <div
                        className="uploadCoustomBtn actionCard__list-status"
                        style={{ backgroundColor: "#096dd9", color: "white" }}
                      >
                        {t("main.upload")}
                        <FileUpload
                          multiple
                          value={files}
                          onChange={(e: File[]) => {
                            uploadDocument(ChildData?.id, [...files, ...e]);
                            setFiles([...files, ...e]);
                          }}
                        />
                      </div>
                    </Box>
                  </Box>
                  // )
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box className="uploadBlock">
            <Box mb={3} className="ActionStatus">
              <span className="ActionStatus_box">
                {" "}
                {t("main.home.action_needed")}
              </span>
              {t("main.home.please_complete_these")}
              <strong> {t("main.home.intake_forms")}</strong> .
            </Box>
            <>
              <Grid container spacing={3} mt="20px">
                {currentChild?.organizationOptions?.form?.IntakePacketForm?.filter(
                  (form: any) =>
                    form.status === "Started" ||
                    form.status === "Sent" ||
                    form.status === "Saved"
                )?.map((item: any, index: number) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                      <div className="documentCard">
                        <div className="documentCard__img">
                          <div className="documentCard__img-card">
                            {item.form.formSchema
                              ? i18n.language === "es" &&
                                item.form.formSchema.locale === "es"
                                ? item.form.formSchema.title?.es
                                : item.form.formSchema.title?.default ||
                                  item.form.formSchema.title
                              : item.form.name}
                          </div>
                        </div>
                        <div className="documentCard__btn">
                          <span
                            style={{ backgroundColor: "#096dd9" }}
                            onClick={() => {
                              if (item.form.url !== null) {
                                setIntakeFormUrl(item.form.url);
                              } else {
                                setIntakeFormUrl("");
                              }
                              handleOpenForm(item, index);
                            }}
                            className={
                              item.status === "Sent"
                                ? "blueBg"
                                : item.status === "Saved"
                                ? "yellowBg"
                                : item.status === "Mapped"
                                ? "hidebg"
                                : "greenBg"
                            }
                          >
                            {item.status === "Sent" ? (
                              <>{t("main.home.fill_forms")}</>
                            ) : item.status === "Saved" ? (
                              <>{t("main.home.fill_forms")}</>
                            ) : (
                              item.status
                            )}
                          </span>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </>
            {/* ) : (
            ""
          )} */}
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Box className="uploadBlock">
            <Box mb={3} className="ActionStatus">
              <span className="ActionStatus_box">
                {" "}
                {t("main.home.action_needed")}
              </span>
              {t("main.home.please_complete_these")}
              <strong> {t("main.home.assessment")}</strong>.
            </Box>

            <Grid container spacing={3} mt="20px">
              {currentChild?.organizationOptions?.form?.AssessmentForm?.filter(
                (form) =>
                  form.status === "Started" ||
                  form.status === "Sent" ||
                  form.status === "Saved"
              )?.map((item: any, index: number) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                    <div className="documentCard">
                      <div className="documentCard__img">
                        <div className="documentCard__img-card">
                          {item.form.name}
                        </div>
                      </div>
                      <div className="documentCard__btn">
                        <span
                          style={{ backgroundColor: "#096dd9" }}
                          onClick={() => handleOpenForm(item, index)}
                          className={
                            item.status === "Sent"
                              ? "blueBg"
                              : item.status === "Saved"
                              ? "yellowBg"
                              : "greenBg"
                          }
                        >
                          {item.status === "Sent" ? (
                            <>
                              {t("main.home.take") +
                                " " +
                                t("main.home.assessment")}
                            </>
                          ) : item.status === "Saved" ? (
                            <>{t("main.home.nedd_to_complete")}</>
                          ) : (
                            item.status
                          )}
                        </span>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box className="uploadBlock">
            <Box mb={3} className="ActionStatus">
              <span className="ActionStatus_box">
                {" "}
                {t("main.home.action_needed")}
              </span>
              {t("main.home.please_complete_these")}
              <strong> {t("main.home.signature_Requests")}</strong>.
            </Box>
            <Grid container spacing={3} mt="20px">
              {currentChild?.organizationOptions?.form?.SessionForm?.filter(
                (form) => form.status === "Signature Requested"
              )?.map((item: any, index: number) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                    <div className="documentCard">
                      <div className="documentCard__img">
                        <div className="documentCard__img-card">
                          {item.form.name}
                        </div>
                      </div>
                      <div className="documentCard__btn">
                        <span
                          style={{ backgroundColor: "#096dd9" }}
                          onClick={() => handleOpenForm(item, index)}
                          className="blueBg"
                        >
                          {t("main.home.signature_Requested")}
                        </span>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>

        {popup &&
          requestStatus &&
          (requestStatus === "Started" ||
            requestStatus === "Saved" ||
            requestStatus === "Sent" ||
            requestStatus === "Signature Requested") && (
            <Dialog open={saveClient} className="smallModel">
              <div>
                <DialogContent>
                  {t("main.home.open_page_message")}
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                  <Button
                    className="border-button"
                    variant="contained"
                    onClick={() => {
                      setPopup(false);
                      setSaveClient(false);
                    }}
                    color="secondary"
                  >
                    No
                  </Button>
                  <Button
                    className="contained-button"
                    onClick={() => {
                      if (intakeFormUrl) {
                        return window.location.replace(intakeFormUrl);
                      }
                      navigate("/assessment", {
                        state: {
                          childId: ChildData.id,
                          formId: formId,
                          FormNo: count,
                        },
                      });
                    }}
                  >
                    {t("main.yes")}
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          )}
        {open && (
          <Dialog open={open} className="smallModel">
            <div>
              <DialogContent className="fileConfirm-dialog">
                {t("main.home.file-uplaod-message")}
                <div>
                  <Button variant="contained" onClick={() => setIsOpen(false)}>
                    {t("main.home.close")}
                  </Button>
                </div>
              </DialogContent>
            </div>
          </Dialog>
        )}
      </Grid>
    </>
  );
};

export default HomeSubPage;
